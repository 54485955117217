import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  嗨.
    </Text>
    <p>{`我是一名游戏开发者，`}</p>
    <p>{`最近在使用 `}<a parentName="p" {...{
        "href": "https://www.cocos.com"
      }}>{`Cocos Creator`}</a>{` 游戏引擎时`}</p>
    <p>{`发布了一个提供引擎增强与优化的开源项目：`}<a parentName="p" {...{
        "href": "https://github.com/smallmain/cocos-enhance-kit"
      }}>{`Cocos Enhance Kit`}</a></p>
    <p>{`欢迎你和我们一起来完善这个项目！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      